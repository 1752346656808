<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import moment from 'moment'
import DatePicker from "vue2-datepicker";

import jsPDF from 'jspdf' 
// import html2canvas from "html2canvas"
import VueHtml2pdf from 'vue-html2pdf'
import autoTable from 'jspdf-autotable'

export default {
  name: 'Report',
  page: {
    title: "Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, VueHtml2pdf },
  data() {
    return {

      title: "Report",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "report",
          active: true,
        },
      ],

      htmlToPdfOptions: {
        margin: [15,5,15,5],
        filename: `testing.pdf`,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          // unit: "pt",
          format: 'a4',
          orientation: 'portrait',
        },
      },


      json_fields: {
        No: 'index',
        Date: "ldate",
        Account: "account",
        // Admin: "admin_name",
        Settlement: "settlement",
        Discount: "discount",
        Interest: "interest",
        Debit: "debit",
        Credit: "credit",
      },
      daterange: "",
      report_list: [],

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return 0
      // return this.invoicelist.length;
    },
    generateByDateRange(){
      if(this.daterange.length > 0){
        return this.daterange[0]+'-'+this.daterange[1]
      }
      else{
        return 'empty'
      }
    },
    compute_settlement(){
      let total = 0
      this.report_list.forEach(function(item){
        total += parseFloat(item.settlement || 0)
      })
      return total.toFixed(2)
    },
    compute_discount(){
      let total = 0
      this.report_list.forEach(function(item){
        total += parseFloat(item.discount || 0)
      })
      return total.toFixed(2)
    },
    compute_interest(){
      let total = 0
      this.report_list.forEach(function(item){
        total += parseFloat(item.interest || 0)
      })
      return total.toFixed(2)
    },
    compute_debit(){
      let total = 0
      this.report_list.forEach(function(item){
        total += parseFloat(item.debit || 0)
      })
      return total.toFixed(2)
    },
    compute_credit(){
      let total = 0
      this.report_list.forEach(function(item){
        total += parseFloat(item.credit || 0)
      })
      return total.toFixed(2)
    },
  },
  watch:{
    daterange(){
      this.generateReport()
    }
  },
  mounted() {

  },
  methods: {
    fixDate(event){
      if(event){
        return moment(event).format('YYYY-MM-DD');
      }
      else{
        return ''
      }
    },
    generateReport(){
      let self = this
      const baseURI = 'reports'
      let formData = new FormData()
      self.report_list = []
      formData.append('start_date', this.daterange[0])
      formData.append('end_date', this.daterange[1])
      self.$http.post(baseURI, formData)
      .then((result) => {
        if(result.data.status == 'success'){
          // for(var a = 0; a<10; a++){
            result.data.data.forEach(function(item,index){
              self.report_list.push({
                index: (index+1).toString(),
                ldate: item.ldate,
                account: item.account,
                admin_name: item.admin_name,
                settlement: item.credit !== '' ? item.settlement || '0' : '0',
                discount: item.discount || '0',
                interest: item.interest || '0',
                debit: item.debit || '0',
                credit: item.credit || '0'
              })
            })
          // }
        }
      })
    },
    normal_one() {
      let doc2 =new jsPDF()
      autoTable(doc2, { html: '#my-table' })
      doc2.save(`${this.generateByDateRange}.pdf`)
       
    },
    // async beforeDownload ({ html2pdf, options, pdfContent }) {
    //     console.log(html2pdf, options, pdfContent)

    //       await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
    //         // console.log(pdf.internal.getNumberOfPages(), pdfContent)
    //           const totalPages = pdf.internal.getNumberOfPages()
    //           for (let i = 1; i <= totalPages; i++) {
    //               pdf.setPage(i)
    //               pdf.setFontSize(10)
    //               pdf.setTextColor(150)
    //               pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.2))
    //           } 
    //       }).save()
    // },
    // onProgress(event){
    //   console.log('onProgress', event)
    // }
    // this.$refs.html2Pdf.generatePdf()
    download () {
        this.normal_one()
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-5">
        <div class="form-group mb-3">
          <label>Date Range</label>
          <br />
          <!-- confirm -->
          <date-picker v-model="daterange" range append-to-body lang="en" value-type="format" format="YYYY-MM-DD" ></date-picker>
        </div>
      </div>
      <!-- <div class="col-lg-2">
        <b-form-group
          label="Filter by customer"
          label-for="form-filter-cus"
          class="mb-3"
        >
          <select label-for="form-filter-cus" class="form-select" v-model="status">
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
            <option value="DELETED">DELETED</option>
          </select>
        </b-form-group>
      </div>
      <div class="col-lg-2">
        <b-form-group
          label="Filter by loan account"
          label-for="form-filter-loan"
          class="mb-3"
        >
          <select label-for="form-filter-loan" class="form-select" v-model="status">
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
            <option value="DELETED">DELETED</option>
          </select>
        </b-form-group>
      </div> -->
    </div>

    <div class="card" v-show="report_list.length > 0">
      <div class="card-title">
        <button>
          <download-excel
            :data="report_list"
            :fields="json_fields"
            worksheet="Report"
            :stringifyLongNum="true"
            :name="generateByDateRange+'.xls'"
          >
            Download Excel
          </download-excel>
        </button>
        
        <button @click="download">Download PDF</button>
      </div>
      <div class="card-body" style="max-height:400px; overflow-y: scroll;">
        <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="false"
          :paginate-elements-by-height="1000000"
          :manual-pagination="false"
          pdf-content-width="700px"
          
          pdf-format="a4"
          pdf-orientation="portrait"
          :filename="generateByDateRange+'.pdf'"
          :pdf-quality="2"

          
          @beforeDownload="beforeDownload($event)"
          @progress="onProgress($event)"
          ref="html2Pdf"
          >
          <!-- :html-to-pdf-options="htmlToPdfOptions" -->
          <section slot="pdf-content">
              <!-- PDF Content Here -->
              <table class="table table-bordered font-size-12" id="my-table">
                <thead>
                  <!-- <tr>
                    <th colspan="8" class="text-center" style="text-align:center">{{ generateByDateRange }}</th>
                  </tr> -->
                  <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>Account</th>
                    <!-- <th>By</th> -->
                    <th>Settlement</th>
                    <th>Discount</th>
                    <th>Interest</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in report_list" :key="index">
                    <td>{{ item.index }}</td>
                    <td>{{ item.ldate }}</td>
                    <td>{{ item.account }}</td>
                    <!-- <td>{{ item.admin_name }}</td> -->
                    <td>{{ item.settlement }}</td>
                    <td>{{ item.discount }}</td>
                    <td>{{ item.interest }}</td>
                    <td>{{ item.debit }}</td>
                    <td>{{ item.credit }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <!-- <td></td> -->
                    <td>{{ compute_settlement }}</td>
                    <td>{{ compute_discount }}</td>
                    <td>{{ compute_interest }}</td>
                    <td>{{ compute_debit }}</td>
                    <td>{{ compute_credit }}</td>
                  </tr>
                </tfoot>
              </table>
          </section>
        </vue-html2pdf>
      </div>
    </div>
        
  </Layout>
</template>
